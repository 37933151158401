const domain = window.location.hostname;
const getBaseURL = () => {
  switch (process.env.VUE_APP_ENVIRONMENT) {
    case 'dev':
      return 'https://dev-backend.buzzpowerai.com';
    case 'stage':
      return 'https://api-stage.buzzpowerai.com';
    case 'mig':
      return 'https://dev.mig-backend.buzzpowerai.com/';
    case 'mig-prod':
      return domain === 'de.buzzpowerai.com'
        ? 'https://de-backend.buzzpowerai.com/'
        : 'https://mig-prod-backend.buzzpowerai.com/';
    case 'mig-stage':
      return 'https://stage.mig-backend.buzzpowerai.com/';
    case 'main':
      return 'https://api-prod.buzzpowerai.com';
    default:
      return 'http://localhost:4000';
  }
};

const mlBackend = () => {
  if (process.env.NODE_ENV === 'development') return 'https://mig-dev-buzzappengine.poweraiapi.com';

  let endpoint;
  switch (process.env.VUE_APP_ENVIRONMENT) {
    case 'dev':
      endpoint = 'https://devbuzzappengine.poweraiapi.com';
      break;
    case 'stage':
      endpoint = 'https://testbuzzappengine.poweraiapi.com';
      break;
    case 'main':
      endpoint = 'https://buzzappengine.poweraiapi.com';
      break;
    case 'mig':
      endpoint = 'https://mig-dev-buzzappengine.poweraiapi.com';
      break;
    case 'mig-stage':
      endpoint = 'https://stage-buzzappengine.poweraiapi.com';
      break;
    case 'mig-prod':
      endpoint = 'https://mig-prod-buzzappengine.poweraiapi.com';
      break;
    default:
      endpoint = 'https://mig-dev-buzzappengine.poweraiapi.com';
      break;
  }

  return endpoint;
};

export const baseURL = getBaseURL();

export const baseProcessUrl = mlBackend();

export const platformServices = () => (process.env.VUE_APP_ENVIRONMENT ? process.env.VUE_APP_V2_BACKEND_URL : 'http://localhost:8080');
